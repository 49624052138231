import ReactMarkdown from "react-markdown";
import { ReactMarkdownOptions } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";
import rehypeRaw from "rehype-raw";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css"; // rehype-katex does not import the CSS
import remarkMath from "remark-math";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import jsx from "react-syntax-highlighter/dist/esm/languages/prism/jsx";
import tsx from "react-syntax-highlighter/dist/esm/languages/prism/tsx";
import python from "react-syntax-highlighter/dist/esm/languages/prism/python";
import classes from "./MarkdownRenderer.module.css";

import {
  oneDark,
  oneLight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { useTheme } from "@mui/material";
import { CopyToClipboardButton } from "../common/CopyToClipboardButton";

SyntaxHighlighter.registerLanguage("jsx", jsx);
SyntaxHighlighter.registerLanguage("tsx", tsx);
SyntaxHighlighter.registerLanguage("python", python);

type Props = {
  markdown: string;
  inline?: boolean;
};

const bracketReplace = /\\\[(.+)\\\]/g;

export function MarkdownRenderer({ markdown, inline }: Props) {
  const theme = useTheme();
  const syntaxTheme = theme.palette.mode === "dark" ? oneDark : oneLight;

  const preprocessMarkdown = (markdownText: string) => {
    // Replace \[ with $$ and \] with $$ to ensure compatibility
    const processedText = markdownText
      .replace(bracketReplace, '$$$$$1$$$$')
    return processedText;
  };

  const MarkdownComponents: (
    isAnimating: boolean
  ) => ReactMarkdownOptions["components"] = (isAnimating) => ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    code({ className, inline, children, ...props }) {
      const hasLang = /language-(\w+)/.exec(className || "");
      const content = String(children);

      return hasLang ? (
        <div className="relative">
          {!isAnimating && (
            <div className="absolute right-4 top-4">
              <CopyToClipboardButton text={content} />
            </div>
          )}
          <SyntaxHighlighter
            style={syntaxTheme}
            language={hasLang[1]}
            PreTag="div"
            className="codeStyle"
            showLineNumbers={true}
            wrapLines
            useInlineStyles={true}
          >
            {content}
          </SyntaxHighlighter>
        </div>
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
  });

  const remarkMathOptions = {
    singleDollarTextMath: false,
  }

  return (
    <ReactMarkdown
      linkTarget="_blank"
      remarkPlugins={[remarkGfm, supersub, [remarkMath, remarkMathOptions]]}
      // @ts-ignore
      rehypePlugins={[rehypeRaw, rehypeKatex]}
      components={MarkdownComponents(inline ?? false)}
      className={inline ? classes["markdown-inline"] : undefined}
    >
      {preprocessMarkdown(markdown)}
    </ReactMarkdown>
  );
}
