import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import LaunchIcon from "@mui/icons-material/Launch";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import ErrorIcon from "@mui/icons-material/Error";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import tsx from "react-syntax-highlighter/dist/cjs/languages/prism/tsx";
import typescript from "react-syntax-highlighter/dist/cjs/languages/prism/typescript";

import { Cursor } from "./Cursor";
import { MarkdownRenderer } from "../markdown/MarkdownRenderer";
import { ConversationMessage, Source } from "../../types";
import { useRateMessage } from "../../hooks/useRateMessage";
import { useAppState } from "../../state/App";
import { useRequestAnimationFrame } from "../../hooks/useRequestAnimationFrame";
import { CopyToClipboardButton } from "../common/CopyToClipboardButton";
import { RatingDialog } from "./RatingDialog";
import { defineMessages, useIntl } from "react-intl";
import { useSelectedMode } from "../../hooks/useSelectedMode";
import { defaultMode, noInnioMode } from "../../components/common/ModeMenu";
import { commonMessages } from "../../commonMessages";

SyntaxHighlighter.registerLanguage("tsx", tsx);
SyntaxHighlighter.registerLanguage("typescript", typescript);

const LINE_WIDTH = 80;
const ANIMATION_SPEED = 8;

type Props = {
  answer: ConversationMessage;
  showTyping?: boolean;
  hideCursor?: boolean;
  onTypedTextChange?: (text: string) => void;
};

const messages = defineMessages({
  callingFunction: {
    id: "answer.callingFunction",
    defaultMessage: "Calling function ...",
  },
  header: {
    id: "answer.header",
    defaultMessage: "AI-generated content may be incorrect",
  },
});

function makeTTSUrl({
  conversationId,
  messageId,
}: {
  conversationId?: string;
  messageId?: string;
}) {
  if (!conversationId || !messageId) {
    return "";
  }
  return `/api/history/conversation/${conversationId}/message/${messageId}/text-to-speech`;
}

export const Answer = ({
  answer,
  hideCursor,
  showTyping,
  onTypedTextChange: _onTypedTextChange,
}: Props) => {
  const { data: mode } = useSelectedMode();
  // Note: The animation must remain disabled while the mode is loading.
  // Otherwise, the animation might start and can't be stopped until the answer is fully revealed.
  const disableAnswerAnimation = mode?.disable_animation ?? true;
  const [typedText, setTypedText] = useState("");
  const typedIdx = useRef(0);
  const typingDidStart = useRef(false);
  const increment = useRef(1);
  const onTypedTextChange = useRef(_onTypedTextChange);
  onTypedTextChange.current = _onTypedTextChange;

  useEffect(() => {
    function onVisibilityChange() {
      if (document.visibilityState !== "hidden") {
        typedIdx.current = answer.content.length;
        setTypedText(answer.content);
      }
    }
    document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, [answer.content]);

  useEffect(() => {
    if (!typingDidStart.current || disableAnswerAnimation) {
      return;
    }
    const diff = answer.content.length - typedText.length;

    if (diff < 2 * LINE_WIDTH && showTyping) {
      increment.current = 2;
      return;
    }
    if (increment.current !== 4) {
      increment.current = 4;
    }
  }, [
    answer.content.length,
    typedText.length,
    showTyping,
    disableAnswerAnimation,
  ]);

  const showAnimation =
    !disableAnswerAnimation &&
    (showTyping ||
      (typingDidStart.current && typedIdx.current < answer.content.length));

  useRequestAnimationFrame(() => {
    if (!showAnimation) {
      return;
    }

    typingDidStart.current = true;

    typedIdx.current += increment.current;
    if (typedIdx.current > answer.content.length) {
      typedIdx.current = answer.content.length;
    }
    const str = answer.content;
    setTypedText(str.slice(0, typedIdx.current));

    if (answer.content.length && typedIdx.current >= answer.content.length) {
      typingDidStart.current = false;
    }
  }, ANIMATION_SPEED);

  const text = showAnimation ? typedText : answer.content;
  // Note: if the animation is disabled we still show the cursor at the beginning
  // of the answer so that the user is aware of the state
  const showCursor =
    (showAnimation || answer.content.length === 0) && !hideCursor;

  useEffect(() => {
    onTypedTextChange.current?.(text);
  }, [text, showAnimation]);

  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent
        sx={{
          pt: 0,
          pb: "0 !important",
        }}
      >
        <AnswerHeader answer={answer} />
        <div className="flex-grow py-4">
          <MemoizedMDRenderer
            markdown={text ?? "&nbsp;"}
            inline={showAnimation}
          />
          {showCursor && <Cursor />}
        </div>
        {!showAnimation && <AnswerActions answer={answer} />}
      </CardContent>
    </Card>
  );
};

const MemoizedMDRenderer = React.memo(MarkdownRenderer);

export const AnswerHeader = ({ answer }: Props) => {
  const { formatMessage: t } = useIntl();
  const theme = useTheme();
  const canRate = answer._id && answer.conversation_id && answer.rating !== -2;
  const {
    state: { currentTTSUrl, isPlaying },
    actions: { updateMessage, setCurrentTTSUrl, setIsPlaying },
  } = useAppState();
  const { mutateAsync: rateMessage, isLoading } = useRateMessage();
  const [currentRating, setCurrentRating] = useState<number | undefined>(
    undefined
  );
  const isRatingDialogOpen =
    typeof currentRating !== "undefined" && currentRating !== 0;

  const ttsUrl = makeTTSUrl({
    conversationId: answer.conversation_id,
    messageId: answer._id,
  });

  const isPlayingAudio = isPlaying && currentTTSUrl === ttsUrl;
  const isCallingFunction = answer.calling_function;

  const rate = async (_rating: number, comment?: string) => {
    if (!answer._id) {
      return;
    }
    const rating = answer.rating === _rating ? 0 : _rating;
    await rateMessage({
      conversationId: answer.conversation_id,
      messageId: answer._id,
      rating,
      comment,
    });
    updateMessage({
      ...answer,
      rating,
      rating_comment: comment,
    });
  };

  const getModeName = (name: string) => {
    if (name === defaultMode) {
      return t(commonMessages.defaultMode);
    }
    if (name === noInnioMode) {
      return t(commonMessages.noInnioMode);
    }
    return name;
  };

  const startRating = (_rating: number) => {
    if (answer.rating === _rating) {
      rate(0);
      return;
    }
    setCurrentRating(_rating);
  };
  const closeRatingDialog = () => {
    setCurrentRating(undefined);
  };

  const startAudioStream = () => {
    if (!answer._id || !answer.conversation_id) {
      return;
    }
    setCurrentTTSUrl(ttsUrl);
    setIsPlaying(true);
  };

  const stopAudioStream = () => {
    setIsPlaying(false);
  };

  return (
    <>
      <RatingDialog
        open={isRatingDialogOpen}
        onClose={closeRatingDialog}
        onSubmit={(comment) => {
          rate(currentRating || 0, comment);
          closeRatingDialog();
        }}
      />
      <CardHeader
        sx={{
          pb: "0 !important",
          px: "0 !important",
          "&": {
            flexWrap: "wrap !important",
            ".MuiCardHeader-content": {
              pb: 2,
            },
            ".MuiCardHeader-action": {
              flexGrow: 1,
              maxWidth: "100%",
            },
          },
        }}
        title={
          <Typography
            variant="caption"
            color={theme.palette.action.disabled}
            sx={{
              mr: 2,
            }}
          >
            {t(messages.header)}
          </Typography>
        }
        action={
          <div className="flex justify-end items-center">
            {answer.mode_name && (
              <Chip
                className="overflow-y-hidden"
                label={getModeName(answer.mode_name)}
                size="small"
                variant="outlined"
                color="secondary"
              ></Chip>
            )}
            {!answer.mode_name && answer.no_innio_context && (
              <Chip
                label="Disabled INNIO Content"
                size="small"
                variant="outlined"
                color="warning"
              ></Chip>
            )}
            <Tooltip
              title={
                isCallingFunction
                  ? t(messages.callingFunction)
                  : answer.function_content?.replaceAll("|", ", ")
              }
              enterTouchDelay={0}
              leaveTouchDelay={3000}
            >
              <span>
                <IconButton
                  aria-label="settings"
                  size="small"
                  disabled={!answer.function_content}
                >
                  {isCallingFunction ? (
                    <CircularProgress size={20} />
                  ) : (
                    <InfoOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Play">
              <span>
                <IconButton
                  aria-label="settings"
                  size="small"
                  disabled={!answer.conversation_id || !answer._id}
                  onClick={() =>
                    isPlayingAudio ? stopAudioStream() : startAudioStream()
                  }
                >
                  {isPlayingAudio ? (
                    <StopCircleIcon fontSize="small" />
                  ) : (
                    <PlayCircleOutlineIcon fontSize="small" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <CopyToClipboardButton
              size="small"
              text={answer.content}
              disabled={!answer.content}
            />
            <Tooltip title={answer.rating === 1 && answer.rating_comment}>
              <span>
                <IconButton
                  aria-label="settings"
                  size="small"
                  disabled={!canRate || isLoading}
                  onClick={startRating.bind(null, 1)}
                >
                  {answer.rating === 1 ? (
                    <ThumbUpIcon fontSize="small" />
                  ) : (
                    <ThumbUpAltOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={answer.rating === -1 && answer.rating_comment}>
              <span>
                <IconButton
                  aria-label="rating"
                  size="small"
                  disabled={!canRate || isLoading}
                  onClick={startRating.bind(null, -1)}
                >
                  {answer.rating === -1 ? (
                    <ThumbDownIcon fontSize="small" />
                  ) : (
                    <ThumbDownOutlinedIcon fontSize="small" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </div>
        }
      />
    </>
  );
};

type SourceChunked = {
  id: string;
  name: string;
  chunks: Source[];
  has_source_link: boolean;
};

function AnswerActions({ answer }: Props) {
  const [selectedDocumentId, setSelectedDocumentId] = useState<
    string | undefined
  >(undefined);

  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    documentId: string
  ) => {
    setSelectedDocumentId(String(documentId));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedDocumentId(undefined);
  };

  const sourcesGrouped = answer.sources?.reduce<Record<string, SourceChunked>>(
    (acc, source) => {
      const key = String(source.document_id);
      if (!acc[key]) {
        acc[key] = {
          id: key,
          name: source.name,
          chunks: [],
          has_source_link: source.has_source_link,
        };
      }
      acc[key].chunks = [...acc[key].chunks, source];
      return acc;
    },
    {}
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const isSourceSelected = typeof selectedDocumentId !== "undefined";
  const selectedSourceChunks = isSourceSelected
    ? sourcesGrouped?.[selectedDocumentId]
    : null;

  if (!answer.sources?.length) return null;

  return (
    <>
      <Divider />
      <div className="my-2 flex flex-wrap flex-grow items-center">
        <List dense>
          {Object.entries(sourcesGrouped || []).map((source, idx) => (
            <Link
              href=""
              target="_blank"
              rel="noopener"
              underline="none"
              key={idx}
              onClick={(e) => {
                e.preventDefault();
                handleClick(e, source[0]);
              }}
            >
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={source[1].name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
      <Popover
        id={id}
        open={open && isSourceSelected}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            sx(theme) {
              return {
                [theme.breakpoints.up("md")]: {
                  maxWidth: "42rem",
                  maxHeight: "28rem",
                },
              };
            },
          },
        }}
      >
        <div className="m-4">
          {selectedSourceChunks?.has_source_link ? (
            <Link
              href={`/api/history/conversation/${answer.conversation_id}/message/${answer._id}/document/${selectedSourceChunks?.id}`}
              target="_blank"
              rel="noopener"
              underline="none"
            >
              <Typography variant="h6" className="mb-4 flex items-center">
                <LaunchIcon className="mr-2" />
                {selectedSourceChunks?.name || "Source"}
              </Typography>
            </Link>
          ) : (
            <Typography variant="h6" className="mb-4 flex items-center">
              {selectedSourceChunks?.name || "Source"}
            </Typography>
          )}

          {selectedSourceChunks?.chunks.map((chunk, idx) => (
            <div key={idx}>
              <div>
                <Typography className="whitespace-pre-line">
                  {chunk.content}
                </Typography>
              </div>
              <Divider className="my-8" />
            </div>
          ))}
        </div>
      </Popover>
    </>
  );
}

export const ErrorAnswer = ({ answer }: Props) => {
  return (
    <Card variant="outlined">
      <CardContent className="!pb-4 max-h-80 overflow-y-auto">
        <div className="flex items-center">
          <div className="mr-2">
            <ErrorIcon color="warning" />
          </div>
          <div className="flex flex-col">
            <Typography className="mb-2">Error</Typography>
            <Typography variant="body2">{answer.content}</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
