export enum RouteNames {
  ROOT = "/",
  LOGIN = "/login",
  LOGOUT = "/logout",
  LOGIN_ERROR = "/error",
  CHAT = "/chat",
  APPS = "/apps/:slug?",
  KNOWLEDGE_BASE = "/knowledge-base",
  MODE_IMPORT = "/mode-import/:hash",
}
