import { ApiError, useApiClient } from "@myplant-io/utils/api";
import { useMutation, UseMutationOptions, useQueryClient } from "react-query";

type ModeWithFile = {
  name: string;
  temperature: number;
  system_prompt_addition: string;
  no_innio_context: boolean;
  document_types: string[] | null;
  attachments?: File[];
  disable_animation: boolean;
};

export function useModeCreate(
  options?: UseMutationOptions<unknown, ApiError, ModeWithFile>
) {
  const client = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<{ _id: string }, ApiError, ModeWithFile>(
    ({
      name,
      temperature,
      system_prompt_addition,
      no_innio_context,
      document_types,
      attachments,
      disable_animation,
    }) => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("temperature", temperature.toString());
      formData.append("system_prompt_addition", system_prompt_addition);
      formData.append("no_innio_context", no_innio_context.toString());
      formData.append("document_types", document_types?.join(",") ?? "");
      attachments?.forEach((file) => {
        formData.append("attachments", file);
      });
      formData.append("disable_animation", disable_animation.toString());
      return client.fetch(`/api/modes`, {
        method: "POST",
        body: formData,
      });
    },
    {
      async onSettled() {
        await queryClient.invalidateQueries({
          queryKey: ["modes"],
        });
      },
      ...options,
    }
  );
}
