import { LinearProgress } from "@mui/material";
import { useApiClient } from "@myplant-io/utils/api";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { useQuery } from "react-query";

type User = {
  username: string;
  fullName: string;
};

type AuthState = {
  user?: User;
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
};

const AuthContext = createContext<AuthState>({
  user: undefined,
  isLoading: false,
  isFetched: false,
  isError: false,
});

export const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const client = useApiClient();
  const {
    data: user,
    isLoading,
    isFetched,
    isError,
  } = useQuery<User>(["user"], () => client.fetch("/me"), {
    retry: false,
  });

  const value = useMemo(
    () => ({
      user,
      isLoading,
      isFetched,
      isError,
    }),
    [user, isLoading, isFetched, isError]
  );

  return (
    <AuthContext.Provider value={value}>
      {isFetched ? children : <LinearProgress />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
