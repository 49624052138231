import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogButton,
} from "@myplant-io/mui";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { defineMessages, useIntl } from "react-intl";
import { yup } from "@myplant-io/utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Checkbox,
  DialogActions,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { commonMessages } from "../../commonMessages";
import { defaultTemperature, TemperatureSelector } from "./TemperatureSlider";
import {
  DocumentTypesAutocomplete,
  DocumentTypesHeading,
} from "./DocumentTypeSelector";
import { Mode, DocumentType } from "../../types";
import { useEffect } from "react";
import { useDocumentTypes } from "../../hooks/useDocumentType";
import { DisableAnimationSelector } from "./DisableAnimationSwitch";
import {
  FilesHeading,
  FilesPreview,
  FileUploadButton,
  FileWithPreview,
  useFileUploadProps,
} from "./FilesSelector";

const FORM_ID = "mode-edit-dialog";

export const messages = defineMessages({
  title: {
    id: "modeDialog.title",
    defaultMessage: "Mode settings",
  },
  noInnioContext: {
    id: "modeDialog.noInnioContext",
    defaultMessage: "Disable INNIO Knowledge",
  },
  systemPromptAddition: {
    id: "modeDialog.systemPromptAddition",
    defaultMessage: "Instructions",
  },
});

type ModeData = {
  _id?: string;
  name: string;
  temperature: number;
  systemPromptAddition: string;
  documentTypes: string[];
  noInnioContext: boolean;
  disableAnswerAnimation: boolean;
  attachments: FileWithPreview[];
};

type Props = {
  mode?: Mode;
  open: boolean;
  onClose: () => void;
  onSubmit: (mode: ModeData & { attachments: File[] }) => Promise<void>;
  onDelete: (id: string) => void;
};

export function ModeUpdateDialog({
  mode,
  open,
  onClose,
  onSubmit,
  onDelete,
}: Props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const { previewProps, buttonProps } = useFileUploadProps();
  const { formatMessage: t } = useIntl();
  const schema = yup
    .object({
      _id: yup.string(),
      name: yup.string().required(),
      temperature: yup.number().required(),
      systemPromptAddition: yup.string().default("").notRequired(),
      documentTypes: yup.array().of(yup.string().required()).required(),
      noInnioContext: yup.boolean().required(),
      disableAnswerAnimation: yup.boolean().required(),
      attachments: yup.array().required(),
    })
    .required();

  const {
    handleSubmit,
    watch,
    setValue,
    control,
    reset,
    formState: { isSubmitting, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      temperature: defaultTemperature,
      systemPromptAddition: "",
      documentTypes: [],
      noInnioContext: false,
      disableAnswerAnimation: false,
      attachments: [],
    },
  });

  const { data: allDocumentTypes } = useDocumentTypes();

  const setFiles = previewProps.setFiles;
  useEffect(() => {
    const files =
      mode?.attachments?.map((file) => {
        const f: FileWithPreview = new File([""], file.file_name, {});
        f.id = file._id;
        return f;
      }) ?? [];

    setFiles(files);
    reset({
      _id: mode?._id,
      name: mode?.name ?? "",
      temperature: mode?.temperature ?? defaultTemperature,
      systemPromptAddition: mode?.system_prompt_addition ?? "",
      documentTypes: mode?.document_types ?? [],
      noInnioContext: mode?.no_innio_context ?? false,
      disableAnswerAnimation: mode?.disable_animation ?? false,
      attachments: files,
    });
  }, [mode, setFiles, reset]);

  const temperature = watch("temperature");
  const documentTypes = watch("documentTypes");
  const noInnioContext = watch("noInnioContext");
  const disableAnswerAnimation = watch("disableAnswerAnimation");
  const attachments = watch("attachments");

  const documents = allDocumentTypes?.map((doc) => ({
    ...doc,
    selected: documentTypes?.includes(doc.id),
  })) as DocumentType[];
  const _onSubmit: SubmitHandler<ModeData> = async (data) => {
    await onSubmit({
      _id: data._id,
      name: data.name ?? "",
      temperature: data.temperature,
      systemPromptAddition: data.systemPromptAddition ?? "",
      documentTypes: data.documentTypes,
      noInnioContext: data.noInnioContext,
      disableAnswerAnimation: data.disableAnswerAnimation,
      attachments: data.attachments,
    });
    reset();
  };

  return (
    <Dialog open={open} fullWidth fullScreen={isSmall} maxWidth="md">
      <DialogTitle onClose={onClose}>{t(messages.title)}</DialogTitle>
      <DialogContent>
        <form
          id={FORM_ID}
          onSubmit={handleSubmit(_onSubmit)}
          className="flex flex-col"
        >
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <>
                <Typography variant="h6" id="input-slider" gutterBottom>
                  {t(commonMessages.name)}
                </Typography>
                <TextField
                  {...field}
                  disabled={mode?.read_only ?? field.disabled}
                  label={t(commonMessages.name)}
                  value={field.value ?? ""}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              </>
            )}
          />
          <div className="mt-8">
            <TemperatureSelector
              disabled={mode?.read_only}
              temperature={temperature}
              onChange={(temperature) => {
                setValue("temperature", temperature);
              }}
            />
          </div>
          <div className="mt-8">
            <Typography variant="h6" id="input-slider" gutterBottom>
              {t(messages.systemPromptAddition)}
            </Typography>
            <Controller
              control={control}
              name="systemPromptAddition"
              render={({ field, fieldState }) => (
                <>
                  <TextField
                    {...field}
                    multiline
                    minRows={1}
                    maxRows={10}
                    disabled={mode?.read_only ?? field.disabled}
                    label={t(messages.systemPromptAddition)}
                    value={field.value ?? ""}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    fullWidth
                  />
                </>
              )}
            />
          </div>
          <div className="mt-8">
            <DocumentTypesHeading />
            <Controller
              control={control}
              name="noInnioContext"
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        checked={field.value}
                        disabled={mode?.read_only ?? field.disabled}
                      />
                    }
                    label={t(messages.noInnioContext)}
                  />
                </FormGroup>
              )}
            />
          </div>
          <div className="mt-2">
            <DocumentTypesAutocomplete
              disabled={mode?.read_only || noInnioContext}
              documents={documents}
              onChange={(documents) => {
                const types = documents
                  .filter(({ selected }) => selected)
                  .map(({ id }) => id);
                setValue("documentTypes", types);
              }}
            />
          </div>
          <div className="mt-8">
            <DisableAnimationSelector
              disabled={mode?.read_only}
              disableAnswerAnimation={disableAnswerAnimation}
              onChange={(v) => {
                setValue("disableAnswerAnimation", v);
              }}
            />
          </div>
          <div className="mt-8">
            <FilesHeading />
            <div className="flex items-center">
              <FilesPreview
                {...previewProps}
                onDeleteFile={(file) => {
                  setValue(
                    "attachments",
                    attachments.filter((f) => f.name !== file.name)
                  );
                  previewProps.onDeleteFile(file);
                }}
              />
              <div className="ml-4">
                <FileUploadButton
                  {...buttonProps}
                  onChange={(event) => {
                    const newAttachments = Array.from(event.target.files ?? []);
                    setValue("attachments", [
                      ...attachments,
                      ...newAttachments,
                    ]);
                    buttonProps.onChange(event);
                  }}
                />
              </div>
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions className="flex justify-between">
        <DialogButton
          type="submit"
          variant="destructive"
          disabled={!mode?._id}
          onClick={() => {
            if (mode?._id) onDelete(mode._id);
          }}
        >
          {t(commonMessages.delete)}
        </DialogButton>
        <LoadingButton
          form={FORM_ID}
          type="submit"
          variant="outlined"
          loading={isSubmitting}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          disabled={
            mode?.read_only || isSubmitting || (isSubmitted && !isValid)
          }
        >
          {t(commonMessages.save)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
