import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  disableAnimation: {
    id: "disableAnimation",
    defaultMessage: "Disable Animation",
  },
});

export function DisableAnimationSelector(props: Props) {
  return (
    <>
      <DisableAnimationHeading />
      <DisableAnimationSwitch {...props} />
    </>
  );
}

export function DisableAnimationHeading() {
  const { formatMessage: t } = useIntl();
  return (
    <Typography variant="h6" id="input-slider" gutterBottom>
      {t(messages.disableAnimation)}
    </Typography>
  );
}

type Props = {
  disableAnswerAnimation: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
};

export function DisableAnimationSwitch({
  disableAnswerAnimation,
  onChange,
  disabled,
}: Props) {
  const { formatMessage: t } = useIntl();

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={disableAnswerAnimation}
            onChange={(e) => onChange(e.target.checked)}
            disabled={disabled}
          />
        }
        label={t(messages.disableAnimation)}
      />
    </FormGroup>
  );
}
