import { useApiClient } from "@myplant-io/utils/api";
import { useMutation } from "react-query";

export function useModeShare() {
  const client = useApiClient();
  return useMutation(
    ({
      id,
      readonly: readOnly = false,
    }: {
      id: string;
      readonly?: boolean;
    }) => {
      return client.fetch<{ share_hash: string }>(
        `/api/modes/${id}/share?read_only=${readOnly}`,
        {
          method: "POST",
        }
      );
    }
  );
}
