import {
  Autocomplete,
  Checkbox,
  Chip,
  createFilterOptions,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { defineMessages, useIntl } from "react-intl";
import { useDocumentTypes } from "../../hooks/useDocumentType";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { DocumentType } from "../../types";
import { Fragment } from "react";

const messages = defineMessages({
  documentType: {
    id: "document.source",
    defaultMessage: `{cnt, plural,
      =1 {Document Type}
      other {Document Types}
    }`,
  },
  innoKnowledge: {
    id: "document.innoKnowledge",
    defaultMessage: "INNIO Knowledge",
  },
  selectAll: {
    id: "document.selectAll",
    defaultMessage: "Select All",
  },
});
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const SELECT_ALL = "SELECT_ALL";

export function DocumentTypesSelector(props: Props) {
  return (
    <>
      <DocumentTypesHeading />
      <DocumentTypesAutocomplete {...props} />
    </>
  );
}

export function DocumentTypesHeading() {
  const { formatMessage: t } = useIntl();
  return (
    <Typography variant="h6" id="input-slider" gutterBottom>
      {t(messages.innoKnowledge)}
    </Typography>
  );
}

type Props = {
  documents?: DocumentType[];
  onChange: (documents: DocumentType[]) => void;
  disabled?: boolean;
};

export function DocumentTypesAutocomplete({
  documents,
  onChange,
  disabled,
}: Props) {
  const { formatMessage: t } = useIntl();
  const { data: allDocumentTypes } = useDocumentTypes();

  const handleClick = (documents?: DocumentType[]) => {
    const _ids = documents?.map(({ id }) => id);
    const docs =
      allDocumentTypes?.map((doc) => ({
        ...doc,
        selected: _ids?.includes(doc.id) ?? false,
      })) ?? [];
    onChange(docs);
  };

  const selectedDocumentTypes = documents?.filter(({ selected }) => selected);
  const selectedCount = selectedDocumentTypes?.length ?? 0;
  const isAllSelected = allDocumentTypes?.length === selectedCount;

  return (
    <>
      <Autocomplete
        fullWidth
        multiple
        options={allDocumentTypes ?? []}
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        value={selectedDocumentTypes ?? []}
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        filterOptions={(options, params) => {
          const filter = createFilterOptions<DocumentType>();
          const filtered = filter(options, params);
          return [
            {
              label: t(messages.selectAll),
              id: SELECT_ALL,
              selected: isAllSelected,
            },
            ...filtered,
          ];
        }}
        onChange={(_, newValue) => {
          if (newValue.find((option) => option.id === SELECT_ALL)) {
            return onChange(
              allDocumentTypes?.length === selectedDocumentTypes?.length
                ? []
                : allDocumentTypes ?? []
            );
          }

          handleClick(newValue);
        }}
        renderOption={(props, option, { selected }) => (
          <Fragment key={option.id}>
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={option.id === SELECT_ALL ? isAllSelected : selected}
              />
              {option.label}
            </li>
            {option.id === SELECT_ALL && <Divider />}
          </Fragment>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              size="small"
              label={option.label}
              {...getTagProps({ index })}
              key={option.id}
            />
          ))
        }
        renderInput={(params) => (
          <TextField {...params} label={t(messages.documentType, { cnt: 2 })} />
        )}
      />
    </>
  );
}
