import { TextField, Typography } from "@mui/material";
import { Dialog, DialogTitle, DialogContent } from "@myplant-io/mui";
import { defineMessages, useIntl } from "react-intl";
import { CopyToClipboardButton } from "./CopyToClipboardButton";

const messages = defineMessages({
  title: {
    id: "modeShare.title",
    defaultMessage: "Share Mode",
  },
  info: {
    id: "modeShare.info",
    defaultMessage: "Share this link with others to share the mode.",
  },
  shareLink: {
    id: "modeShare.shareLink",
    defaultMessage: "Share Link",
  },
  shareLinkReadonly: {
    id: "modeShare.shareLinkReadonly",
    defaultMessage: "Share Link (Readonly)",
  },
});
type Props = {
  readonlyHash: string;
  hash?: string;
  open: boolean;
  onClose: () => void;
};

export function ModeShareDialog({
  hash: _hash,
  readonlyHash: _readonlyHash,
  open,
  onClose,
}: Props) {
  const { formatMessage: t } = useIntl();
  const handleClose = () => {
    onClose();
  };

  const generateLink = (h: string) => {
    return `${window.location.origin}/mode-import/${h}`;
  };

  const hash = _hash && generateLink(_hash);
  const readonlyHash = generateLink(_readonlyHash);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle onClose={handleClose}>{t(messages.title)}</DialogTitle>
      <DialogContent>
        <div>
          <Typography>{t(messages.info)}</Typography>
        </div>
        {hash && (
          <div className="mt-8 flex items-center">
            <TextField
              label={t(messages.shareLink)}
              disabled
              value={hash}
              fullWidth
              className="mr-4"
            />
            <CopyToClipboardButton text={hash} />
          </div>
        )}
        <div className="mt-8 flex items-center">
          <TextField
            label={t(messages.shareLinkReadonly)}
            disabled
            value={readonlyHash}
            fullWidth
            className="mr-4"
          />
          <CopyToClipboardButton text={readonlyHash} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
