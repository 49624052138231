import { useSnackbar } from "@myplant-io/snackbar";
import {
  createApiClient,
  getApiBaseUrl,
  ApiError,
  ApiClientProvider as _ApiClientProvider,
} from "@myplant-io/utils/api";
import { PropsWithChildren } from "react";
import { defineMessages, useIntl } from "react-intl";

const messages = defineMessages({
  unauthorized: {
    id: "upstream.error.unauthorized",
    defaultMessage: "You seem to have been logged out.",
  },
  reload: {
    id: "upstream.error.unauthorized.action.reload",
    defaultMessage: "Reload",
  },
});

export function ApiClientProvider(props: PropsWithChildren<unknown>) {
  const intl = useIntl();
  const { showErrorMessage } = useSnackbar();

  const apiClient = createApiClient({
    baseUrl: getApiBaseUrl("/"),
    onError: (error) => {
      const statusCode = (error as ApiError)?.statusCode;
      if (
        statusCode &&
        [401, 403].includes(statusCode) &&
        !(
          location.pathname === "/" ||
          location.pathname === "/login" ||
          location.pathname.startsWith("/mode-import")
        )
      ) {
        showErrorMessage({
          message: intl.formatMessage(messages.unauthorized),
          action: intl.formatMessage(messages.reload),
          handleAction: () => location.reload(),
        });
      }
      throw error;
    },
  });

  return (
    <_ApiClientProvider apiClient={apiClient}>
      {props.children}
    </_ApiClientProvider>
  );
}
