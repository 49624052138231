import { createInstance, MatomoProvider } from "@datapunt/matomo-tracker-react";
export { useMatomo } from "@datapunt/matomo-tracker-react";
import { PropsWithChildren, useEffect, useState } from "react";
import { useAuth } from "./Auth";

type MatomoOptions = {
  urlBase: string;
  siteId: number;
  linkTracking?: boolean;
  userId?: string;
};

export function createMatomoClient(options: MatomoOptions) {
  return createInstance({
    linkTracking: false, // optional, default value: true
    ...options,
  });
}

export function TrackingProvider({ children }: PropsWithChildren<unknown>) {
  const { user } = useAuth();
  const [client, setClient] = useState<ReturnType<typeof createInstance>>(
    createMatomoClient({
      urlBase: "https://usageanalytics.myplant.io",
      siteId: 68,
      userId: user?.username,
    })
  );

  useEffect(() => {
    if (user) {
      setClient(
        createMatomoClient({
          urlBase: "https://usageanalytics.myplant.io",
          siteId: 68,
          userId: user.username,
        })
      );
    }
  }, [user]);

  if (!client) {
    return null;
  }

  { /* @ts-expect-error MatomoProvider types outdated */ }
  return <MatomoProvider value={client}>{children}</MatomoProvider>;
}
