import { Outlet, useLocation } from "react-router-dom";
import { useAppState } from "../../state/App";
import { Disclaimer, useDisclaimer } from "../../components/common/Disclaimer";
import {
  AIDrawerBackground,
  AppBar,
  Drawer,
  ListItemType,
  NavAction,
  useAppStateContext,
} from "@myplant-io/app-components";
import { IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import SchoolIcon from "@mui/icons-material/School";
import HistoryIcon from "@mui/icons-material/History";
import AddIcon from "@mui/icons-material/Add";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useAuth } from "../../state/Auth";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { MyAppsLauncher } from "@myplant-io/app-launcher";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useUsageTracking } from "@myplant-io/hooks";
import { AILogoNeg, AILogoPos } from "@myplant-io/logos";
import { defineMessages } from "react-intl";
import { RouteNames } from "../../RouteNames";

const messages = defineMessages({
  chat: {
    id: "chat",
    defaultMessage: "Chat",
  },
  knowledgeBase: {
    id: "knowledgeBase",
    defaultMessage: "Knowledge Base",
  },
  logout: {
    id: "logout",
    defaultMessage: "Logout",
  },
});

const drawerItems: ListItemType[] = [
  {
    id: "chat",
    label: messages.chat,
    Icon: ChatIcon,
    routePattern: /chat/,
    action: {
      type: NavAction.history,
      path: RouteNames.CHAT,
    },
    permission: "",
  },
  {
    id: "knowledgeBase",
    label: messages.knowledgeBase,
    Icon: SchoolIcon,
    routePattern: /knowledge-base/,
    action: {
      type: NavAction.history,
      path: RouteNames.KNOWLEDGE_BASE,
    },
    permission: "",
  },
  {
    id: "Logout",
    label: messages.logout,
    Icon: ExitToAppIcon,
    routePattern: /logout/,
    action: {
      type: NavAction.history,
      path: RouteNames.LOGOUT,
    },
    permission: "",
  },
];
const Layout = () => {
  const { accepted } = useDisclaimer();
  const theme = useTheme();
  const {
    state: { messages, isChatHistoryOpen },
    actions: { setChatHistory, setCurrentConversationId, setMessages },
  } = useAppState();
  const {
    actions: {
      ui: { setDrawerOpen },
    },
  } = useAppStateContext();
  const is2lg = useMediaQuery(theme.breakpoints.up("2lg"));

  const authCtx = useAuth();
  const location = useLocation();
  const { trackEvent, trackPageView, enableLinkTracking } = useMatomo();

  enableLinkTracking();

  const { mutate: track } = useUsageTracking({ wsBaseUrl: "/" });

  const navigate = useNavigate();

  useEffect(() => {
    track({
      appId: "LOSD83Q3XIGXJ",
      eventType: "login",
    });
  }, [track]);

  useEffect(() => {
    // track page view on each location change
    if (location.pathname) {
      trackPageView({
        documentTitle: document.title,
      });
    }
  }, [location, trackPageView]);

  const newChat = () => {
    setCurrentConversationId(undefined);
    setMessages([]);
  };

  const actions = (
    <div className="flex flex-row">
      {messages.length > 0 && !is2lg && (
        <Tooltip key="new" title="Start new conversation">
          <IconButton
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              newChat();
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      {location.pathname.includes("chat") && (
        <Tooltip
          key="history"
          title={isChatHistoryOpen ? "Hide chat history" : "Show chat history"}
        >
          <IconButton
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              setChatHistory(!isChatHistoryOpen);
            }}
          >
            <HistoryIcon />
          </IconButton>
        </Tooltip>
      )}
      <MyAppsLauncher
        edge="end"
        wsBaseUrl="/"
        onNavigate={navigate}
        onTrackEvent={trackEvent}
      />
    </div>
  );

  return (
    <div className="h-full flex flex-col">
      <AppBar
        title="myAI Assistant"
        actions={actions}
        onDrawerOpen={() => setDrawerOpen?.(true)}
      />
      <Drawer
        userName={authCtx?.user?.fullName ?? ""}
        additionalInfo={"User"}
        background={<AIDrawerBackground />}
        logo={theme.palette.mode === "dark" ? <AILogoNeg /> : <AILogoPos />}
        drawerItems={drawerItems}
        preferredLanguage={"en"}
        supportedLanguages={[{ language: "en", label: "English" }]}
        handleLanguageChange={() => {}}
        handleClose={() => setDrawerOpen?.(false)}
        handleDrawerItemClick={(item) => {
          if (item.type === NavAction.history) {
            navigate(item.path);
          }
          setDrawerOpen?.(false);
          return false;
        }}
      />
      <Outlet />

      {authCtx.isFetched && authCtx.user && !accepted && <Disclaimer />}
    </div>
  );
};

export default Layout;
