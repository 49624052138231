import React, { PropsWithChildren, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  AppStateProvider as CommonsAppStateProvider,
  aiThemes,
  useAppStateContext,
  ThemeType,
} from "@myplant-io/app-components";
import {
  createTheme,
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material";

import "./index.css";

import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { AppStateProvider } from "./state/App";
import { RouteNames } from "./RouteNames";
import { LandingPage } from "./pages/LandingPage";
import { IntlProvider, useIntl } from "react-intl";
import { AuthProvider, useAuth } from "./state/Auth";
import { LoginPage } from "./pages/LoginPage";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SnackbarProvider, useSnackbar } from "@myplant-io/snackbar";
import { ApiClientProvider } from "./state/Api";
import { TrackingProvider } from "./state/Tracking";
import { AllAppsPageView } from "@myplant-io/app-launcher";
import { LicenseInfo } from "@mui/x-data-grid-premium";
import { TextToSpeechProvider } from "./state/TextToSpeech";
import KnowledgeBase from "./pages/knowledge-base/KnowledgeBase";
import { ModeImport } from "./pages/knowledge-base/ModeImport";
import { LogoutPage } from "./pages/LogoutPage";
// @ts-expect-error vite
import { registerSW } from "virtual:pwa-register";
import { commonMessages } from "./commonMessages";

// license for material-ui x
LicenseInfo.setLicenseKey(
  "a3f709faec43797c44156e2ac7e889bbTz03OTU0OCxFPTE3MzI4MDI2NTIwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    placeholder: string;
    paperLevel2: string;
    accent: string;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    "2lg": true; // additional breakpoint
    xl: true;
  }
}

export function useColorScheme() {
  const matcher = window.matchMedia
    ? window.matchMedia("(prefers-color-scheme: dark)")
    : false;
  const [isDark, setIsDark] = useState(matcher ? matcher.matches : false);

  useEffect(() => {
    if (!matcher) {
      return;
    }

    const listener = (e: MediaQueryListEvent) => {
      setIsDark(e.matches);
    };
    matcher.addEventListener("change", listener);
    return () => matcher.removeEventListener("change", listener);
  }, [matcher]);
  return isDark;
}

const PrivateRoute = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation();
  const { user, isFetched } = useAuth();

  if (!isFetched) {
    return null;
  }

  if (!user) {
    return <Navigate to={RouteNames.LOGIN} state={{ from: location }} />;
  }
  return <>{children}</>;
};

export const AllAppsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div className="p-4 px-4 sm:px-6">
      <AllAppsPageView
        sx={{ flex: 1 }}
        locationPathname={location.pathname}
        wsBaseUrl="/"
        onNavigate={(path) => navigate(path)}
      />
    </div>
  );
};

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={RouteNames.LOGIN} element={<LoginPage />} />
      <Route path={RouteNames.LOGOUT} element={<LogoutPage />} />
      <Route index element={<LandingPage />} />
      <Route element={<Layout />}>
        <Route path={RouteNames.APPS} element={<AllAppsPage />} />
        <Route
          path={RouteNames.CHAT}
          element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          }
        />
        <Route
          path={RouteNames.KNOWLEDGE_BASE}
          element={
            <PrivateRoute>
              <KnowledgeBase />
            </PrivateRoute>
          }
        />
        <Route
          path={RouteNames.MODE_IMPORT}
          element={
            <PrivateRoute>
              <ModeImport />
            </PrivateRoute>
          }
        />
      </Route>
    </>
  )
);

const queryClient = new QueryClient();

export function ThemeProvider({ children }: PropsWithChildren<unknown>) {
  const {
    theme: { type },
  } = useAppStateContext();

  const themeConfig = type === ThemeType.Light ? aiThemes.light : aiThemes.dark;
  const {
    MuiDialogContent,
    MuiDialogTitle,
    MuiDialogActions,
    MuiPaper,
    ...rest
  } = themeConfig.components ?? {};
  const theme = createTheme({
    ...themeConfig,
    typography: {
      fontFamily: [
        "innio",
        "Segoe UI",
        "Segoe UI Web (West European)",
        "Segoe UI",
      ].join(","),
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 768,
        lg: 1024,
        "2lg": 1120, // additional breakpoint
        xl: 1280,
      },
    },
    components: {
      ...rest,
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: "1rem !important",
          },
        },
      },
    },
  });

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
}

function UpdateServiceWorker() {
  const { formatMessage: t } = useIntl();
  const { showInfoMessage } = useSnackbar();
  useEffect(() => {
    let i: number | undefined = undefined;
    const updateSW = registerSW({
      onRegistered(r: ServiceWorkerRegistration) {
        if (r) {
          i = setInterval(() => {
            r.update();
          }, 1000 * 60);
        }
      },
      onNeedRefresh() {
        showInfoMessage({
          message: t(commonMessages.newUpdateAvailable),
          action: t(commonMessages.reload),
          handleAction: () => {
            updateSW();
          },
        });
      },
    });
    return () => {
      if (i) {
        clearInterval(i);
      }
    };
  }, [showInfoMessage, t]);
  return null;
}

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <CommonsAppStateProvider>
        <AppStateProvider>
          <IntlProvider locale={"en"}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider>
                <CssBaseline />
                <SnackbarProvider>
                  <ApiClientProvider>
                    <AuthProvider>
                      <TrackingProvider>
                        <TextToSpeechProvider>
                          <UpdateServiceWorker />
                          <RouterProvider router={router} />
                        </TextToSpeechProvider>
                      </TrackingProvider>
                    </AuthProvider>
                  </ApiClientProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </IntlProvider>
        </AppStateProvider>
      </CommonsAppStateProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
